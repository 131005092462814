import 'gijgo';

$('#datepicker').datepicker({
  uiLibrary: 'bootstrap4',
  // value: date.toLocaleDateString('en-AU'),
  format: 'yyyy-mm-dd',
  disableDaysOfWeek: [0, 6],
  disableDates: function(date) {
    const currentDate = new Date();
    return date > currentDate ? true : false;
  },
  modal: true,
  header: true,
  footer: true
});
