const CONFIG = {
  workspace_id: '7633222766753',
  pa_token: '0/50e4bd48ef742cf5c4ca65cc2707d1f0',
  projects_id: '1120691940870388',
  assignee_id: '1122519697718712',

  fieldInterface: {
    cost: {
      id: '1127907098602421'
    },
    invoice_status: {
      id: '1120692468866974',
      options: {
        is_invoiced: '1120692468866975',
        not_invoiced: '1120692468866976',
        unsure: '1120692468866977'
      }
    },

    priority: {
      id: '1124734387184531',
      options: {
        low: '1124734387184532',
        medium: '1124734387184533',
        high: '1124734387184534'
      }
    }
  }
};

const TASK_ROUTE = `${CONFIG.workspace_id}/tasks?opt_fields=html_notes`;

export function createTaskPayload(msgPayload) {
  const { cost, invoice_status, priority } = CONFIG.fieldInterface;
  const { clientTask, invoiceAmount, dueDate, isUrgent } = msgPayload;
  const html_notes = constructBodyHTML(msgPayload);

  return {
    async: true,
    crossDomain: true,
    url: `https://app.asana.com/api/1.0/workspaces/${TASK_ROUTE}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${CONFIG.pa_token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      projects: CONFIG.projects_id,
      assignee: CONFIG.assignee_id,
      name: clientTask,
      html_notes: html_notes,
      due_on: dueDate,
      custom_fields: {
        [cost.id]: invoiceAmount,
        [invoice_status.id]:
          invoiceAmount > 0
            ? invoice_status.options.is_invoiced
            : invoice_status.options.not_invoiced,
        [priority.id]: isUrgent ? priority.options.high : null
      }
    }
  };
}

function constructBodyHTML({
  fullName,
  emailAddress,
  requestDetail,
  acceptanceCriteria
}) {
  return /*html*/ `<body>
    <strong>📛 Name:</strong>
    ${fullName}

    <strong>💌 Email:</strong>
    <a href="mailto:${emailAddress}">${emailAddress}</a>

    <strong>📝 Request Detail:</strong>
    ${requestDetail}

    <strong>⭐ Definition of Done:</strong>
    ${acceptanceCriteria}

  </body>`;
}
