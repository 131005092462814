// Page Styles
import '../../../styles/index.scss';
import '../../../styles/vendor/signin.css';

// UI Functionality
import '../ui_global';
import './ui/datepicker';

import { createTaskPayload } from '../../asana/api-post-task';

const form = document.querySelector('#request-form');
const clearUserDetailsBtn = document.querySelector('.clear-userDetails');

// INITIALIZE FORM
initFormWithUserDetails();

// RESTORE OLD FORM DATA IF NOT SENT.
setInputsWithUnsentData();

// FORGET USER DETAILS
clearUserDetailsBtn.addEventListener('click', el => {
  localStorage.removeItem('userDetails');
  el.target.innerText = 'Details Forgotten! 🙈';
});

function initFormWithUserDetails() {
  let userDetails = localStorage.getItem('userDetails');

  if (!userDetails) return;

  document
    .querySelectorAll('input[name="fullName"], input[name="emailAddress"]')
    .forEach(input => {
      input.value = JSON.parse(userDetails)[input.name];
    });

  clearUserDetailsBtn.classList.remove('d-none');
}

function storeUserDetailsLocally(data) {
  const { fullName, emailAddress } = data;

  localStorage.setItem(
    'userDetails',
    JSON.stringify({ fullName, emailAddress })
  );
}

// FORM SUBMIT HANDLER
form.addEventListener('submit', e => {
  e.preventDefault();

  const msgPayload = {};
  const formData = new FormData(e.target);

  disableInputStateToggle(e.target);

  // Map form value to object keys.
  formData.forEach((val, key) => (msgPayload[key] = val));

  // Store user details for next load.
  storeUserDetailsLocally(msgPayload);

  $.ajax(createTaskPayload(msgPayload))
    .done(resp => {
      // remove saved item if present.
      localStorage.removeItem('unsubmittedTaskData');

      const taskId = resp.data.gid;
      sessionStorage.setItem('submittedTaskId', taskId);
      location.pathname = `/thanks.html`;
    })
    .catch(err => {
      // Store unsent form data for later.
      localStorage.setItem('unsubmittedTaskData', JSON.stringify(msgPayload));

      alert(
        `Hmmm, something went wrong. I've saved the data for you so you don't loose it. Try again in a second. 🚀`
      );
    });
});

function setInputsWithUnsentData() {
  const taskData = JSON.parse(localStorage.getItem('unsubmittedTaskData'));
  const fields = [
    'fullName',
    'emailAddress',
    'clientTask',
    'invoiceAmount',
    'dueDate',
    'requestDetail',
    'acceptanceCriteria'
  ];

  if (!!taskData) {
    // Add data to inputs
    fields.forEach(field => {
      const input = document.querySelector(
        `form input[name="${field}"], form textarea[name="${field}"]`
      );
      input.value = taskData[field];
    });

    // Show reset button and blind event listener.
    const resetFrom = document.querySelector('.clear-formValues');
    resetFrom.classList.remove('d-none');
    resetFrom.addEventListener('click', () => {
      localStorage.removeItem('unsubmittedTaskData');
      location.reload();
    });
  }
}

function disableInputStateToggle(formElm) {
  const inputs = formElm.querySelectorAll(
    'input, textarea, button[type="submit"]'
  );

  const spinner = formElm.querySelector('.loading-spinner');
  spinner.classList.toggle('d-none');

  // Add styles to chips
  document
    .querySelectorAll('.input-group-prepend, .input-group-append')
    .forEach(el => el.classList.toggle('disabled'));

  inputs.forEach(input => {
    input.disabled = !input.disabled;
  });
}
